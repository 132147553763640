.imageOverlay {
  display: inline-block;
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
}

.imageOverlay__container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.imageOverlay__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageOverlay__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--theme-transparency);
  transition: var(--transition)
}

.imageOverlay__container:hover .imageOverlay__overlay {
  opacity: 0;
}