.about{
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transition: all, opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  max-width: 900px;
  margin: 0px auto;
  padding: 100px 0px;
}

.about__info {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
}

.about__texts p {
  margin: 0px 0px 15px;
}

.about__skills {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
}

.about__skills li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.about__skills li::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: var(--theme);
  font-size: var(--fz-sm);
  line-height: 12px;
}

.about__image {
  position: relative;
  display: inline-block;
  border-radius: var(--border-radius);
  cursor: pointer;
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  height: auto;
}

.about__image img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

@media (max-width: 768px) {
  .about {
    padding: 80px 0px;
  }

  .about__info {
    display: block;
  }
  
  .about__image {
    display: block;
    margin: 50px auto 0px;
    width: 70%;
  }
}

@media (max-width: 480px) {
  .about {
    padding: 60px 0px;
  }
}
