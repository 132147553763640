.experience{
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 715px;
}

.experience__inner{
  display: flex;
  min-height: 340px;
}

.experience__texts {
  position: relative;
  width: 100%;
  margin-left: 20px;
}

@media (max-width: 768px) {
  .experience {
      padding: 80px 0px;
  }
}

@media (max-width: 600px) {
  .experience__inner {
    display: block;
    padding: 0px;
  }

  .experience{
    padding: 60px 0px;
  }

  .experience__texts{
    margin-left: 0px;
  }
}