.experienceNav {
  position: relative;
  z-index: 3;
  width: max-content;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.experienceNav__btn{
  border: none;
  border-radius: 0px;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: var(--transition);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: var(--tab-height);
  padding: 0px 20px 2px;
  border-left: 2px solid var(--font-color-medium);
  background-color: transparent;
  color: var(--font-color-light);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
}

.experienceNav__btn:hover{
  color: var(--theme);
  background-color: var(--bg-color-light);
}

.experienceNav__btn-selected {
  color: var(--theme);
}

.experienceNav__scroll {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 2px;
  height: var(--tab-height);
  border-radius: var(--border-radius);
  background: var(--theme);
  transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

@media (max-width: 768px) {
  .experienceNav__btn {
      padding: 0px 15px 2px;
  }
}

@media (max-width: 600px) {
  .experienceNav {
    display: flex;
    overflow-x: auto;
    width: calc(100% + 100px);
    padding-left: 50px;
    margin-left: -50px;
    margin-bottom: 30px;
  }

  .experienceNav__btn {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    min-width: 120px;
    padding: 0px 15px;
    border-left: 0px;
    border-bottom: 2px solid white;
    text-align: center;
  }

  .experienceNav__scroll {
    top: auto;
    bottom: 0px;
    width: 100%;
    max-width: var(--tab-width);
    height: 2px;
    margin-left: 50px;
  }
}

@media (max-width: 480px) {
  .experienceNav {
      width: calc(100% + 50px);
      padding-left: 25px;
      margin-left: -25px;
  }

  .experienceNav__scroll {
      margin-left: 25px;
  }
}