.experiencePanel {
  width: 100%;
  height: auto;
  padding: 10px 5px;
}

.experiencePanel__title {
  margin: 0px 0px 10px;
  margin-bottom: 2px;
  font-size: var(--fz-xxl);
  font-weight: 500;
  line-height: 1.3;
  color: var(--font-color-light);
}

.experiencePanel__author {
  color: var(--theme);
}

a.inline-link {
  display: inline-block;
  position: relative;
  color: var(--theme);
  transition: var(--transition);
}

a.inline-link::after {
  content: "";
  display: block;
  width: 0px;
  height: 1px;
  position: relative;
  bottom: 0.37em;
  background-color: var(--theme);
  opacity: 0.5;
  transition: var(--transition);
}

.experiencePanel__date {
  margin-bottom: 25px;
  color: var(--font-color-medium);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.experiencePanel__list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-size: var(--fz-lg);
}

.experiencePanel__element {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.experiencePanel__element::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: var(--theme);
}

.experiencePanel__button {
  display: flex;
  position: relative;
  width: 120px;
  height: 48px;
  margin: 0 auto;
  margin-top: 30px;
}

.experiencePanel__button .button{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .experiencePanel {
    display: block;
    margin-left: 0px;
    padding: 0px;
  }
}