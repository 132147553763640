.achievements{
  position: relative;
  margin: 0px;
  display: block;
  flex-direction: column;
  width: calc(100%);
  min-height: 100vh;
  padding: 200px 150px;
}

.achievements__title{
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
  font-weight: 600;
  color: var(--font-color-light);
  line-height: 1.1;
}

.achievements__subtitle{
  color: var(--theme);
  margin: 0px 0px 20px;
  font-size: var(--fz-md);
  font-family: var(--font-mono);
  font-weight: 400;
  line-height: 1.5;
}

.achievements__table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 100px;
}

.achievements__table th, .achievements__table td {
  padding: 10px;
  text-align: left;
  word-wrap: break-word;
}
*, ::before, ::after {
  box-sizing: inherit;
}

.achievements__table tr {
  cursor: default;
}

.achievements__table th:first-child, .achievements__table td:first-child {
  padding-left: 20px;
}

.achievements__table th:last-child, .achievements__table td:last-child {
  padding-right: 20px;
}


.achievements__table tr td:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.achievements__table tr td:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

@media (max-width: 1080px) {
  .achievements {
    padding: 200px 100px;
  }
}

@media (max-width: 768px) {
  .achievements {
    padding: 150px 50px;
  }
  
  .achievements__table {
    margin: 50px -10px;
  }

  .achievements__table th:first-child, .achievements__table td:first-child {
    padding-left: 10px;
  }

  .achievements__table th:last-child, .achievements__table td:last-child {
    padding-right: 10px;
  }  
}

@media (max-width: 500px) {
  .achievements{
    padding: 125px 25px;
  }

  .achievements__table-school{
    display: none;
  }
}