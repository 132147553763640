.footer {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 70px;
  padding: 15px;
  text-align: center;
}

.footer__inner {
  color: var(--font-color-light);
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1;
}

.footer__link {
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}

.footer__link:hover {
  color: var(--theme);
}

.footer__stats {
  margin-top: 10px;
}

.footer__stats > span {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px 7px;
}

.footer__stats svg {
  display: inline-block;
  margin-right: 5px;
  width: 14px;
  height: 14px;
  color: inherit;
}

.footer__stats svg.feather {
  fill: none;
}

.footer__social{
  display: none;
}

.footer__social-list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.footer__social-link {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
  padding: 10px;
}

.footer__social-element svg{
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .footer__social {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0px auto 10px;
    color: var(--light-slate);
  }
}