.emailBar {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  z-index: 10;
  color: var(--font-color-medium);
}

.emailBar__text::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: var(--font-color-medium);
}

.emailBar__link {
  margin: 20px auto;
  padding: 10px;
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: var(--fz-lg);
  letter-spacing: 0.1em;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}

.emailBar__link:hover{
  color: var(--theme);
  transform: translateY(-3px);
}

@media screen and (max-width: 1080px) {
  .emailBar{
    right: 20px;
  }
}

@media (max-width: 768px) {
  .emailBar {
    display: none;
  }
}