@font-face {
  font-family: 'Dela Gothic';
  src: url('../fonts/DelaGothic/DelaGothic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Code';
  src: url('../fonts/FiraCode/FiraCode.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibre';
  src: url('../fonts/Calibre/Calibre-Regular.woff2') format('woff2'),
       url('../fonts/Calibre/Calibre-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibre';
  src: url('../fonts/Calibre/Calibre-Medium.woff2') format('woff2'),
       url('../fonts/Calibre/Calibre-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibre';
  src: url('../fonts/Calibre/Calibre-Semibold.woff2') format('woff2'),
       url('../fonts/Calibre/Calibre-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}