.projectCard{
  transition: 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: visible;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  position: relative;
  cursor: default;
}

.projectCard a{
  position: relative;
  z-index: 1;
}

.projectCard__inner {
  box-shadow: 0 10px 30px -15px var(--shadow);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  padding: 2rem 1.75rem;
  border-radius: var(--border-radius);
  background-color: var(--bg-color-light);
  transition: var(--transition);
  overflow: auto;
  cursor: pointer;
}

.projectCard__inner:hover {
  transform: translateY(-7.5px);
}

.projectCard__inner:hover .projectCard__title{
  color: var(--theme);
}

.projectCard__top {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 35px;
}

.folder svg {
  width: 40px;
  height: 40px;
  fill: none;
  color: var(--theme);
}

.projectCard__links{
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: -10px;
  color: var(--font-color-light);
}

.projectCard__links a {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 5px 7px;
}

.projectCard__link {
  width: 22px;
  height: 22px;
  margin-top: -4px;
  color: var(--font-color-light);
  transition: var(--transition);
}

.projectCard__link:hover {
  color: var(--theme);
}

.projectCard__title {
  margin: 0px 0px 10px;
  color: var(--font-color-light);
  font-size: var(--fz-xxl);
}

.projectCard__title a {
  position: static;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
  font-weight: 600;
}

.projectCard__title a::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.projectCard__description {
  color: var(--font-color-medium);
  font-size: 17px;
}

.projectCard__techList {
  display: flex;
  align-items: flex-end;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0px;
  margin: 20px 0px 0px;
  list-style: none;
}

.projectCard__techList li:not(:last-of-type) {
  margin-right: 15px;
}

.projectCard__techList li {
  font-family: var(--font-mono);
  font-size: var(--fz-xxs);
  line-height: 1.75;
  color: inherit;
}