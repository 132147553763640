.featured{
  margin: 0px auto;
  padding: 100px 0px;
  max-width: 1000px;
}

.featured__list {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.featured__list a {
  position: relative;
  z-index: 1;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  transition: var(--transition);
}

@media (max-width: 768px) {
  .featured {
    padding: 80px 0px;
  }

  .gvznfB:nth-of-type(2n+1) .project-content {
      grid-column: 1 / -1;
      padding: 40px 40px 30px;
      text-align: left;
  }
}

@media screen and (max-width: 480px) {
  .featured {
    padding: 60px 0px;
  }
}