.socialBar {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: 40px;
  right: auto;
  z-index: 10;
  color: var(--font-color-medium);
}

.socialBar__list {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.socialBar__list::after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: var(--font-color-medium);
}

.socialBar__element {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}

.socialBar__link {
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
}

.socialBar__link svg {
  width: 20px;
  height: 20px;
  transition: var(--transition);
  color: var(--font-color-medium);
}

.socialBar__link:hover svg{
  color: var(--theme);
  transform: translateY(-3px);
}

.socialBar__link svg.feather {
    fill: none;
    vertical-align: middle;
}

.socialBar__link svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

@media (max-width: 1080px) {
  .socialBar {
    left: 20px;
  }
}

@media (max-width: 768px) {
  .socialBar {
    display: none;
  }
}