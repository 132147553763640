.navbar{
  width: 100%;
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: fixed;
  transition: var(--transition);
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
}

.navbar-hidden{
  display: none;
}

.navbar-scrolled{
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  position: fixed;
  top: 0px;
  z-index: 100;
  height: var(--nav-scroll-height);
  background-color: var(--nav-scroll-color);
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px -10px var(--shadow);
}

.navbar__logo{
  font-size: 42px;
  font-weight: bold;
  font-family: "Dela Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

.navbar__logo p{
  color: var(--theme);
  text-decoration: none;
}

.navbar__content{
  display: flex;
  padding: 0px 50px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar__links{
  display: flex;
  align-items: center;
  font-family: var(--font-mono);
}

.navbar__list{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__item{
  margin: 0px 5px;
  counter-increment: item 1;
}

.navbar__link{
  font-size: 15px;
  color: var(--font-color-medium);
  padding: 10px;
  text-decoration: none;
  transition: var(--transition);
  cursor: pointer;
}

.navbar__link:hover{
  color: var(--theme);
}

.navbar__link::before {
  content: "0" counter(item) ".";
  margin-right: 5px;
  color: var(--theme);
  text-align: right;
}

.navbar .button{
  padding: 0.75rem 1rem;
  margin-left: 15px;
}

.navbar__menu{
  display: none;
}

.navbar__menu-btn{
  color: var(--theme);
  background: transparent;
  border: none;
  width: 60px;
  height: 60px;
  padding: 0;
  z-index: 110;
}

.navbar__menu-btn svg{
  width: 100%;
  height: 100%;
  color: var(--theme);
}

@media screen and (max-width: 1080px) {
  .navbar__content{
    padding: 0px 40px;
  }
}

@media screen and (max-width: 768px) {
  .navbar__links {
    display: none;
  }

  .navbar__menu {
    display: block;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 110;
    margin-right: -15px;
    padding: 15px;
    border: 0px;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
  }
}

.navbar__aside {
  display: none;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  padding: 50px 10px;
  width: min(75vw, 400px);
  height: 100vh;
  outline: 0px;
  background-color: var(--bg-color-light);
  box-shadow: -10px 0px 30px -15px var(--navy-shadow);
  z-index: 110;
  transform: translateX(0vw);
  visibility: visible;
  transition: var(--transition);
}

.navbar__aside-active {
  display: flex;
}

.navbar__aside-content {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  color: var(--font-color-medium);
  font-family: var(--font-mono);
  text-align: center;
}

.navbar__aside-list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 100%;
}

.navbar__aside-item {
  position: relative;
  margin: 0px auto 20px;
  counter-increment: item 1;
  font-size: clamp(var(--fz-sm), 4vw, var(--fz-lg));
}

.navbar__aside-item::before {
  content: "0" counter(item) ".";
  display: block;
  margin-bottom: 5px;
  color: var(--theme);
  font-size: var(--fz-sm);
}

.navbar__aside-link {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: var(--transition);
  width: 100%;
  padding: 3px 20px 20px;
}

.navbar__aside .button{
  font-size: var(--fz-sm);
  padding: 18px 50px;
  margin: 10% auto 0px;
  width: max-content;
}

