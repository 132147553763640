.hero{
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  height: 100vh;
  padding: 0px;
  max-width: 1000px;
  margin: 0px auto;
}

.hero h1{
  margin: 0px 0px 20px 4px;
  color: var(--theme);
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  font-weight: 400;
}

.hero h2{
  margin: 0px;
  font-size: clamp(40px, 8vw, 80px);
  color: var(--font-color-light);
}

.hero h3{
  margin-top: 5px;
  color: var(--slate);
  line-height: 0.9;
  font-size: clamp(40px, 8vw, 80px);
  font-weight: 600;
}

.hero p{
  margin: 20px 0px 0px;
  max-width: 540px;
  font-family: var(--font-sans);
  font-size: var(--fz-xl);
  line-height: 1.3;
}

.hero .button {
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-sm);
  margin: 50px 0px 0px 0px;
  margin-top: 50px;
}

@media (max-height: 700px) and (min-width: 700px), (max-width: 360px) {
  .hero{
      height: auto;
      padding-top: var(--nav-height);
  }
}