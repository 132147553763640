:root{
	--transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
	--border-radius: 4px;
	--font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
	--font-mono: 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
	--fz-xxs: 12px;
	--fz-xs: 13px;
	--fz-sm: 14px;
	--fz-md: 16px;
	--fz-lg: 18px;
	--fz-xl: 20px;
	--fz-xxl: 22px;
	--fz-heading: 32px;
	--nav-height: 100px;
	--nav-scroll-height: 70px;
	--nav-scroll-color: #1e1e1e98;
	--tab-height: 42px;
	--tab-width: 120px;
	--bg-color: #1e1e1e;
  --bg-color-light: #252526;
	--theme: #aa1313;
	--theme-transparency: #aa131385;
	--theme-dark: #4b1919;
	--font-color: #B0B0B0;
	--font-color-medium: #C2C2C2;
	--font-color-light: #d4d4d4;
	--slate: #8892b0;
	--shadow: #010408b3;
	--navy-shadow: rgba(2, 12, 27, 0.7);
}

body {
	margin: 0px;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	background-color: var(--bg-color);
	color: var(--font-color);
	font-family: var(--font-sans);
	font-size: var(--fz-xl);
	line-height: 1.3;
}

html {
	scrollbar-width: thin;
	scrollbar-color: var(--theme-dark) var(--bg-color);
	box-sizing: border-box;
	width: 100%;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--theme-dark);
	border: 3px solid var(--bg-color);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: var(--bg-color);
}

::selection {
	background-color: var(--theme-dark);
	color: var(--font-color-light);
}

*, ::before, ::after {
	box-sizing: inherit;
}

@media (max-width: 480px) {
	body {
		font-size: var(--fz-lg);
	}
}