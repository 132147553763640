.main {
  position: relative;
  margin: 0px;
  display: block;
  flex-direction: column;
  width: calc(100%);
  min-height: 100vh;
  padding: 0px 150px;
}

.main-disabled {
  filter: blur(5px) brightness(0.7);
  transition: var(--transition);
  pointer-events: none;
  user-select: none;
}

.section__header {
  display: flex;
  align-items: center;
  margin: 10px 0px 40px;
  width: 100%;
}

.section__number {
  margin-right: 10px;
  color: var(--theme);
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-md), 3vw, var(--fz-xl));
  font-weight: 400;
  margin-top: 5px;
  height: clamp(26px, 5vw, var(--fz-heading));
}

.section__title {
  line-height: 1.1;
  white-space: nowrap;
  font-weight: 600;
  font-size: clamp(26px, 5vw, var(--fz-heading));
  color: var(--font-color-light);
}

.section__spacer {
  width: 300px;
  height: 1px;
  border: 0px;
  border-top: 1px solid;
  margin-left: 20px;
  background-color: var(--font-color-light);
}

@media screen and (max-width: 1080px) {
  .main {
    padding: 0px 100px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0px 50px;
  }
}

@media (max-width: 480px) {
  .main {
    padding: 0px 25px;
  }
}