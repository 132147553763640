.contact {
  max-width: 600px;
  margin: 0px auto 100px;
  text-align: center;
  padding: 100px 0px;
}

.contact__overline {
  display: block;
  color: var(--theme);
  font-family: var(--font-mono);
  font-size: var(--fz-lg);
  font-weight: 400;
  -webkit-box-align: center;
  position: relative;
  margin: 10px 0px 40px;
  margin-bottom: 20px;
  width: 100%;
  white-space: nowrap;
  font-family: var(--font-sans);
}

.contact__title {
  margin: 0px 0px 10px;
  font-weight: 600;
  color: var(--font-color-light);
  line-height: 1.1;
  font-size: clamp(40px, 5vw, 60px);
}

.contact p{
  font-family: var(--font-sans);
  font-size: var(--fz-xl);
  line-height: 1.3;
}

.contact .button{
  padding: 1.25rem 1.75rem;
  font-size: var(--fz-sm);
  display: inline-block;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .contact {
    padding: 80px 0px;
    margin: 0px auto 50px;
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 60px 0px;
  }
}